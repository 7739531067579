/* INITIALIZERS + DEFAULTS
 * ============================== */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 90%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Meiryo";
  font-weight: 400;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

.Link {
  color: #626063;
  text-decoration-line: none;
}

.Link:hover {
  color: #89b929;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* BASE APP
  * ============================== */
.App__header {
  background-color: #ffffff;
  /* background-image: url('https://unsplash.it/1000/300?image=823'); */
  background-size: cover;
  color: #626063;
  padding: 1px 1px;
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 4px 2px;
  text-align: center;
}

.Product-wrapper {
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 30px;
  padding: 14px 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: #626063;
}

.flex-row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.button {
  background-color: #ff4500;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #ff7f50;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.button2 {
  background-color: #89b929;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button2:hover,
.button2:focus {
  background-color: #a0c753;
}

.button2:disabled {
  background: #5f811c;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

.btn-border-bottom {
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: small;
  padding: 0.25em 0;
  text-decoration: none;
  color: #626063;
}

.btn-border-bottom:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  top: 100%;
  left: 0;
  border-radius: 3px;
  background: #626063;
  transition: 0.2s;
}

.btn-border-bottom:hover:before {
  background: #ff4500;
  top: -webkit-calc(100% - 3px);
  top: calc(100% - 3px);
}

/* INDEX
  * ============================== */

.Top__img {
  display: auto;
  width: 80%;
  height: 70%;
}

@media screen and (max-width: 480px) {
  .Top__img {
    display: auto;
    width: 100%;
    height: 100%;
  }
}

.head_test {
  max-width: 900px;
  margin:  auto;                 /* デフォルトCSS打ち消し */
  font-size:  24px;           /* 文字サイズ指定 */
  position:  relative;        /* 位置調整 */
  font-weight:  normal;       /* 文字の太さ調整 */
  display:  inline-block;     /* インラインブロックにする */
  padding-top: 20px;          /* 余白指定 */
  padding-left: 20px;         /* 余白指定 */
  color: #626063;
  border-bottom: 3px solid #626063;
}
p {
  margin:  auto;                 /* デフォルトCSS打ち消し */
  line-height: 2;             /* 行間調整 */
}
.head_test:before {
  content:  '';               /* 空白の要素を作る */
  background-color: #ffeaac;  /* 背景色指定 */
  display:  block;            /* ブロック要素にする */
  position:  absolute;        /* 位置調整 */
  left:  0;                  /* 位置調整 */
  height: 70px;               /* 高さ指定 */
  width: 70px;                /* 幅指定 */
  border-radius:  50%;        /* 丸くする */
  top: 0;                     /* 位置調整 */
  z-index:  -1;               /* 重なり調整 */
}

.Card-wrapper {
  max-width: 780px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Title-wrapper {
  max-width: 900px;
  margin: 40px auto 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Category-wrapper {
  max-width: 900px;
  margin: 20px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Category__img{
  display: auto;
  width: 40%;
  height: 40%;
  margin: 10px;
}

@media screen and (max-width: 480px) {
  .Category__img {
    display: auto;
    width: 100%;
    height: 100%;
    margin-left: 10px;
  }
}

.Category {
  /* flex: 0 1 50%; */
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}
@media screen and (max-width: 480px) {
  .Category {
    /* flex: 0 1 48%; */
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 3%;
  }
}

.Recomend{
  display: auto;
  width: 30%;
  height: 30%;
  margin: 15px;
}
@media screen and (max-width: 480px) {
  .Recomend {
    display: auto;
    width: 100%;
    height: 100%;
    margin-left: 10px;
  }
}

.text-align-switch {
  text-align: center; /* パソコン時は中央寄せ */
}
@media screen and (max-width: 559px) {
  .text-align-switch {
    text-align: justify; /* 559px以下だと左寄せ均等になる */
  } 
}

/* CART
  * ============================== */
.Cart {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100vh;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
}

.Cart--open {
  transform: translateX(0);
  z-index: 9999;
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 0px 20px 10px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.Cart__checkout {
  /* margin-top: 15px; */
  display: block;
  width: 100%;
}

.Cart__msg {
  padding: 15px 0px 5px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.Cart-info {
  padding: 18px 10px 10px;
}

.Cart-point {
  padding: 10px 10px 20px;
}

.Cart-derivaly {
  margin: 10px 10px 20px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
  * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 45px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: "Roboto", sans-serif, "Meiryo";
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 12px;
  color: #1e90ff;
  top: 1px;
  opacity: 0.5;
  /* border: 1px solid #767676; */
  border: none;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
  * ============================ */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}
@media screen and (max-width: 480px) {
  .Product {
    flex: 0 1 48%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 3%;
  }
}

.Product__title {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
  font-weight: normal;
}

.Product__price {
  display: block;
  font-size: 1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

.Product__img {
  display: block;
  width: 40%;
  height: 40%;
}

@media screen and (max-width: 480px) {
  .Product__img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
/* FOOTER
  * ============================== */
  .Footer__Link {
    color: #626063;
    text-decoration: none;
    font-size: 75%;
  }
  
  .Footer__Link:hover {
    /* color: #89b929; */
    text-decoration: underline;
  }

/* CUSTOMER AUTH
  * ============================== */

.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}
